<template>
    <div>
        <van-sticky>
            <title-bar2 title="估分排名" />
        </van-sticky>
        <div class="content-box">
            <div class="guide-box">
                <span class="num-box">
                    <span class="num1">1</span>
                    <span class="num2">/</span>
                    <span class="num2">2</span>
                </span>
                <span class="txt">你报考的信息是？</span>
                <span class="total">估分人数：{{ rankCount }}人</span>
            </div>
            <van-form ref="form" class="card-box">
                <van-cell-group title="一志愿报考信息">
                    <van-field is-link readonly label="省市" placeholder="请选择省市" :value="displayValue(1)" :rules="[{ required: true }]" @click="popup1 = true" />
                    <van-field is-link readonly label="学校" placeholder="请选择学校" :value="displayValue(2)" :rules="[{ required: true }]" @click="popup2 = true" />
                    <van-field is-link readonly label="学院" placeholder="请选择学院" :value="displayValue(3)" :rules="[{ required: true }]" @click="popup3 = true" />
                    <van-field is-link readonly label="专业" placeholder="请选择专业" :value="displayValue(4)" :rules="[{ required: true }]" @click="popup4 = true" />
                    <van-field is-link readonly label="学习方式" placeholder="请选择学习方式" :value="displayValue(5)" :rules="[{ required: true }]" @click="popup5 = true" />
                </van-cell-group>
                <van-cell-group title="初试成绩">
                    <van-field label="政治" placeholder="请填写政治分数" type="number" v-model="formData.politicalScore" :rules="[{ required: true }]" />
                    <van-field label="外语" placeholder="请填写外语分数" type="number" v-model="formData.englishScore" :rules="[{ required: true }]" />
                    <van-field label="专业一" placeholder="请填写专业一分数" type="number" v-model="formData.majorScore1" :rules="[{ required: true }]" />
                    <van-field label="专业二" placeholder="请填写专业二分数" type="number" v-model="formData.majorScore2" :rules="[{ required: true }]" />
                    <van-field label="总分" type="number" readonly :value="totalScore" />
                </van-cell-group>
                <van-cell-group title="学生信息">
                    <van-field label="姓名" center placeholder="请填写姓名" v-model="formData.studentName" :rules="[{ required: true }]" />
                    <van-field label="电话" center placeholder="请填写电话" v-model="formData.studentPhone" :rules="[{ required: true }]" />
                    <!-- <van-field label="验证码" center placeholder="请填写验证码" v-model="formData.studentCaptcha" :rules="[{ required: true }]">
                        <template #button>
                            <van-button :disabled="disabled" round size="small" type="primary" native-type="button" @click="onSendCaptcha">获取验证码</van-button>
                        </template>
</van-field> -->
                </van-cell-group>
                <div style="margin: 15px">
                    <van-row gutter="20">
                        <van-col span="12">
                            <van-button round block size="small" type="default" native-type="button" @click="onClearForm">清空</van-button>
                        </van-col>
                        <van-col span="12">
                            <van-button round block size="small" color="#FE5E03" @click="onQueryRank">查看排名情况</van-button>
                        </van-col>
                    </van-row>
                </div>
            </van-form>
            <div class="rank-divider"></div>
            <div class="guide-box">
                <span class="num-box">
                    <span class="num1">2</span>
                    <span class="num2">/</span>
                    <span class="num2">2</span>
                </span>
                <span class="txt-box">
                    <span class="txt">你2025年的考研成绩排名：</span>
                    <span class="txt">第</span>
                    <span class="light"> {{ rankNumber }} </span>
                    <span class="txt">名</span>
                </span>
            </div>
            <div v-if="rankInfos.length > 0">
                <div class="statement-txt">特别声明：成绩排名数据由用户自行填写提交，属于民间自发排名，成绩真实性无法核实，结果仅供参考，本数据与官方无关。</div>
                <van-list class="rank-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <rank-query-item v-for="(item, index) in rankInfos" class="rank-item" :number="index + 1" :universityProvince="item.universityProvince" :universityName="item.UniversityName" :collegeName="item.CollegeName" :majorName="item.MajorName" :majorLearningWay="item.MajorLearningWay" :politicalScore="item.PoliticalScore" :englishScore="item.EnglishScore" :majorScore1="item.MajorScore1" :majorScore2="item.MajorScore2" :totalScore="item.TotalScore" :studentName="item.StudentName" :studentPhone="item.StudentPhone" :img="schoolImg(item.UniversityCode)"></rank-query-item>
                </van-list>
                <div class="card-box item-box">
                    <div class="item-title">复试资料</div>
                    <div class="empty-txt" v-if="documentInfos.length == 0">该科目资料暂未上传，请联系商家上架！</div>
                    <div v-for="(docuemntInfo, index) in documentInfos">
                        <div class="item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</div>
                        <div class="item-price">全国统一零售价：￥{{ docuemntInfo.DocumentPrice }}</div>
                        <div class="item-line" v-if="index < documentInfos.length - 1"></div>
                    </div>
                </div>
                <div class="card-box item-box">
                    <div class="item-title">报录数据</div>
                    <div class="empty-txt" v-if="admitInfos.length == 0">数据统计中，请及时关注本站。</div>
                    <div v-for="(admitInfo, index) in admitInfos">
                        <div class="item-name" @click="onAdmitClick(admitInfo)">{{ admitInfo.AdmitName }}</div>
                        <div class="item-price">数据会员</div>
                        <div class="item-line" v-if="index < admitInfos.length - 1"></div>
                    </div>
                </div>
            </div>
            <div v-else class="card-box">
                <div class="empty-txt">请正确填写报考信息，点“查看排名情况”获取排名数据。</div>
            </div>
        </div>

        <van-popup v-model="popup1" round position="bottom" style="min-height:200px; max-height:80%;">
            <radio-item-group v-model="formData.universityProvince" @change="onSelectChange1(true)">
                <radio-item label="北京" name="北京"></radio-item>
                <radio-item label="天津" name="天津"></radio-item>
                <radio-item label="河北" name="河北"></radio-item>
                <radio-item label="山西" name="山西"></radio-item>
                <radio-item label="辽宁" name="辽宁"></radio-item>
                <radio-item label="吉林" name="吉林"></radio-item>
                <radio-item label="黑龙江" name="黑龙江"></radio-item>
                <radio-item label="上海" name="上海"></radio-item>
                <radio-item label="江苏" name="江苏"></radio-item>
                <radio-item label="浙江" name="浙江"></radio-item>
                <radio-item label="安徽" name="安徽"></radio-item>
                <radio-item label="福建" name="福建"></radio-item>
                <radio-item label="江西" name="江西"></radio-item>
                <radio-item label="山东" name="山东"></radio-item>
                <radio-item label="河南" name="河南"></radio-item>
                <radio-item label="湖北" name="湖北"></radio-item>
                <radio-item label="湖南" name="湖南"></radio-item>
                <radio-item label="广东" name="广东"></radio-item>
                <radio-item label="重庆" name="重庆"></radio-item>
                <radio-item label="四川" name="四川"></radio-item>
                <radio-item label="陕西" name="陕西"></radio-item>
                <radio-item label="内蒙古" name="内蒙古"></radio-item>
                <radio-item label="广西" name="广西"></radio-item>
                <radio-item label="海南" name="海南"></radio-item>
                <radio-item label="贵州" name="贵州"></radio-item>
                <radio-item label="云南" name="云南"></radio-item>
                <radio-item label="西藏" name="西藏"></radio-item>
                <radio-item label="甘肃" name="甘肃"></radio-item>
                <radio-item label="青海" name="青海"></radio-item>
                <radio-item label="宁夏" name="宁夏"></radio-item>
                <radio-item label="新疆" name="新疆"></radio-item>
            </radio-item-group>
        </van-popup>
        <van-popup v-model="popup2" round position="bottom" style="min-height:200px; max-height:80%;">
            <radio-item-group v-model="formData.universityCode" @change="onSelectChange2(true)">
                <radio-item v-for="item in universityInfos" :label="item.UniversityName" :name="item.UniversityCode" icon></radio-item>
            </radio-item-group>
        </van-popup>
        <van-popup v-model="popup3" round position="bottom" style="min-height:200px; max-height:80%;">
            <radio-item-group v-model="formData.collegeCode" @change="onSelectChange3(true)">
                <radio-item v-for="item in collegeInfos" :label="item.CollegeName" :name="item.CollegeCode" icon></radio-item>
            </radio-item-group>
        </van-popup>
        <van-popup v-model="popup4" round position="bottom" style="min-height:200px; max-height:80%;">
            <radio-item-group v-model="formData.majorCode" @change="onSelectChange4(true)">
                <radio-item v-for="item in majorInfos" :label="`${item.MajorCode}${item.MajorName}`" :name="item.MajorCode" icon></radio-item>
            </radio-item-group>
        </van-popup>
        <van-popup v-model="popup5" round position="bottom" style="min-height:200px; max-height:80%;">
            <radio-item-group v-model="formData.majorLearningWay" @change="onSelectChange5(true)">
                <radio-item label="全日制" name="全日制"></radio-item>
                <radio-item label="非全日制" name="非全日制"></radio-item>
            </radio-item-group>
        </van-popup>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import RankQueryItem from "@/components/MT/RankQueryItem";
import RadioItemGroup from "@/components/MT/RadioItemGroup";
import RadioItem from "@/components/MT/RadioItem";
import HeaderEntry from "@/components/MT/HeaderEntry";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";
export default {
    name: "MTModule",
    components: {
        TitleBar2,
        RankQueryItem,
        RadioItemGroup,
        RadioItem,
        HeaderEntry,
    },
    data() {
        return {
            formData: {
                universityProvince: null,
                universityCode: null,
                collegeCode: null,
                majorCode: null,
                majorLearningWay: null,
                politicalScore: 0,
                englishScore: 0,
                majorScore1: 0,
                majorScore2: 0,
                studentName: null,
                studentPhone: null,
                studentCaptcha: null,
            },
            disabled: false,
            popup1: false,
            popup2: false,
            popup3: false,
            popup4: false,
            popup5: false,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            universityInfos: [],
            collegeInfos: [],
            majorInfos: [],
            documentInfos: [],
            admitInfos: [],
            rankInfos: [],
            rankCount: 0,
            rankNumber: 0,
        };
    },
    computed: {
        displayValue() {
            return function (num) {
                if (num == 1) {
                    return this.formData.universityProvince;
                } else if (num == 2) {
                    var items = this.universityInfos.filter(p => p.UniversityCode === this.formData.universityCode);
                    if (items.length > 0) {
                        return items[0].UniversityName;
                    } else {
                        return this.formData.universityCode;
                    }
                } else if (num == 3) {
                    var items = this.collegeInfos.filter(p => p.CollegeCode === this.formData.collegeCode);
                    if (items.length > 0) {
                        return items[0].CollegeName;
                    } else {
                        return this.formData.collegeCode;
                    }
                } else if (num == 4) {
                    var items = this.majorInfos.filter(p => p.MajorCode === this.formData.majorCode);
                    if (items.length > 0) {
                        return items[0].MajorCode + items[0].MajorName;
                    } else {
                        return this.formData.majorCode;
                    }
                } else if (num == 5) {
                    return this.formData.majorLearningWay;
                }
            }
        },
        schoolImg() {
            return function (code) {
                if (code) {
                    return `${Config.school.logo}?SchoolCode=${code}`;
                }
            }
        },
        totalScore() {
            var total = Number(this.formData.politicalScore) + Number(this.formData.englishScore) + Number(this.formData.majorScore1) + Number(this.formData.majorScore2);
            if (isNaN(total)) {
                return 0;
            } else {
                return total;
            }
        },
    },
    methods: {
        //查询成绩排名总数
        getGradeRankCount() {
            var model = {};
            this.$axios
                .post(Config.gradeRank.count, model)
                .then((res) => {
                    this.rankCount = res.data.Data.GradeRankCount;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //添加成绩排名记录
        addGradeRankInfo() {
            var model = {
                UniversityCode: this.formData.universityCode,
                CollegeCode: this.formData.collegeCode,
                MajorCode: this.formData.majorCode,
                MajorLearningWay: this.formData.majorLearningWay,
                PoliticalScore: this.formData.politicalScore,
                EnglishScore: this.formData.englishScore,
                MajorScore1: this.formData.majorScore1,
                MajorScore2: this.formData.majorScore2,
                StudentName: this.formData.studentName,
                StudentPhone: this.formData.studentPhone,
                StudentCaptcha: this.formData.studentCaptcha,
                ProxyCode: this.$store.state.proxyCode,
            };
            this.$axios
                .post(Config.gradeRank.add, model)
                .then((res) => {

                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //查询成绩排名记录
        getGradeRankInfos() {
            var model = {
                UniversityProvince: this.formData.universityProvince,
                UniversityCode: this.formData.universityCode,
                CollegeCode: this.formData.collegeCode,
                MajorCode: this.formData.majorCode,
                MajorLearningWay: this.formData.majorLearningWay,
                PoliticalScore: this.formData.politicalScore,
                EnglishScore: this.formData.englishScore,
                MajorScore1: this.formData.majorScore1,
                MajorScore2: this.formData.majorScore2,
                StudentName: this.formData.studentName,
                StudentPhone: this.formData.studentPhone,
                StudentCaptcha: this.formData.studentCaptcha,
                PageIndex: ++this.index,
                PageSize: 10,
            };
            this.$axios
                .post(Config.gradeRank.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.total = data.TotalCount;
                    this.rankNumber = data.GradeRankNumber;
                    this.rankInfos.push(...data.GradeRankInfos);
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        //获取关联文档列表
        getDocumentInfos() {
            var model = {
                UniversityCode: this.formData.universityCode,
                CollegeCode: this.formData.collegeCode,
                MajorCode: this.formData.majorCode,
            };
            return this.$axios
                .post(Config.gradeRank.related.secondDocumentList, model)
                .then((res) => {
                    this.documentInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取关联报录列表
        getAdmitInfos() {
            var model = {
                UniversityCode: this.formData.universityCode,
                CollegeCode: this.formData.collegeCode,
                MajorCode: this.formData.majorCode,
            };
            return this.$axios
                .post(Config.gradeRank.related.admitList, model)
                .then((res) => {
                    this.admitInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //添加访问商品记录
        addVisitProductRecord() {
            Manager.addVisitProductRecord("估分排名");
        },
        //添加访问学校记录
        addVisitSchoolRecord() {
            Manager.addVisitSchoolRecord({
                StudentPhone: this.formData.studentPhone,
                UniversityCode: this.formData.universityCode,
                CollegeCode: this.formData.collegeCode,
                MajorCode: this.formData.majorCode,
                RecordModule: 9,
            });
        },
        //选择省市改变
        onSelectChange1(clean) {
            this.popup1 = false;
            if (this.formData.universityProvince) {
                if (clean) {
                    this.formData.universityCode = null;
                    this.formData.collegeCode = null;
                    this.formData.majorCode = null;
                    this.formData.majorLearningWay = null;
                    this.universityInfos = [];
                    this.collegeInfos = [];
                    this.majorInfos = [];
                }
                var model = {
                    UniversityProvince: this.formData.universityProvince
                };
                this.$axios
                    .post(Config.gradeRank.filter.schoolList, model)
                    .then((res) => {
                        this.universityInfos = res.data.Data.UniversityInfos;
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //选择大学改变
        onSelectChange2(clean) {
            this.popup2 = false;
            if (this.formData.universityCode) {
                if (clean) {
                    this.formData.collegeCode = null;
                    this.formData.majorCode = null;
                    this.formData.majorLearningWay = null;
                    this.collegeInfos = [];
                    this.majorInfos = [];
                }
                var model = {
                    UniversityCode: this.formData.universityCode
                };
                this.$axios
                    .post(Config.gradeRank.filter.collegeList, model)
                    .then((res) => {
                        this.collegeInfos = res.data.Data.CollegeInfos;
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //选择学院改变
        onSelectChange3(clean) {
            this.popup3 = false;
            if (this.formData.collegeCode) {
                if (clean) {
                    this.formData.majorCode = null;
                    this.formData.majorLearningWay = null;
                    this.majorInfos = [];
                }
                var model = {
                    UniversityCode: this.formData.universityCode,
                    CollegeCode: this.formData.collegeCode
                };
                this.$axios
                    .post(Config.gradeRank.filter.majorList, model)
                    .then((res) => {
                        this.majorInfos = res.data.Data.MajorInfos;
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //选择专业改变
        onSelectChange4(clean) {
            this.popup4 = false;
        },
        //选择专业学习方式改变
        onSelectChange5(clean) {
            this.popup5 = false;
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.rankInfos = [];
            this.addVisitSchoolRecord();
            this.addGradeRankInfo();
            this.getGradeRankInfos();
            this.getDocumentInfos();
            this.getAdmitInfos();
        },
        //加载当前列表
        onLoad() {
            this.getGradeRankInfos();
        },
        //点击清空表单填写
        onClearForm() {
            this.formData = {
                universityProvince: null,
                universityCode: null,
                collegeCode: null,
                majorCode: null,
                majorLearningWay: null,
                politicalScore: 0,
                englishScore: 0,
                majorScore1: 0,
                majorScore2: 0,
                studentName: null,
                studentPhone: null,
                studentCaptcha: null,
            };
        },
        //点击发送验证码
        onSendCaptcha() {
            if (!Tools.checkPhone(this.formData.studentPhone)) {
                this.$toast.fail("请输入正确的手机号码。");
            } else {
                //发送验证码
                var model = {
                    Phone: this.formData.studentPhone,
                };
                this.$axios
                    .post(Config.sms.sendCommonMessage, model)
                    .then((res) => {
                        this.disabled = true;
                        this.$toast.success("验证码发送成功。");
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //点击查看排名情况
        onQueryRank() {
            this.$refs.form.validate().then(() => {
                this.$toast.loading({ message: "正在查询...", duration: 0, forbidClick: true });
                this.onReload();
            }).catch(() => {

            }).finally(() => {
                this.$toast.clear();
            });
        },
        //点击资料
        onDocumentClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            })
        },
        //点击报录数据
        onAdmitClick(admitInfo) {
            this.$router.push({
                name: "Admit1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: this.formData.universityCode,
                    collegeCode: this.formData.collegeCode,
                    majorCode: this.formData.majorCode,
                    majorLearnway: this.formData.majorLearningWay,
                }
            })
        },
    },
    mounted() {
        this.addVisitProductRecord();
        this.getGradeRankCount();
    }
};
</script>

<style scoped lang="less">
.content-box {
    padding: 5px 0;
}

.rank-list {
    margin-top: 10px;
}

.rank-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.rank-divider {
    margin: 20px 0;
}

.guide-box {
    display: flex;
    align-items: flex-end;
    background-color: #F6F6F6;
    padding-left: 10px;
    border-width: 0px;
    font-size: 14px;
    font-weight: 600;

    .num-box {
        display: inline;
        letter-spacing: 5px;

        .num1 {
            color: #FF6602;
            font-size: 18px;
            font-weight: 700;
        }

        .num2 {
            color: gray;
            font-size: 14px;
        }
    }

    .txt-box {
        white-space: pre;

        .txt {
            color: black;
        }

        .light {
            color: #FF6602;
            font-weight: bold;
        }
    }

    .total {
        margin-left: auto;
        margin-right: 10px;
        font-size: 14px;
        font-weight: normal;
    }
}

.card-box {
    background-color: white;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
}


.item-box {

    .item-title {
        font-size: 18px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .item-name {
        color: gray;
        font-size: 14px;
        margin-bottom: 5px;
    }

    .item-name:hover {
        color: #FE5E03;
    }

    .item-price {
        width: 100%;
        display: inline-block;
        text-align: right;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: gray;
        font-size: 14px;
    }

    .item-line {
        border-top: 1px solid #e6e6e6;
        margin: 10px 0px;
    }

}

.empty-txt {
    padding: 10px;
    color: gray;
    font-size: 14px;
}

.statement-txt {
    padding: 10px;
    color: gray;
    font-size: 14px;
}

::v-deep .van-cell-group__title {
    color: black;
    font-weight: bold;
}

::v-deep .van-tabs__nav {
    background-color: #F6F6F6;
}
</style>